<template>
    <modal ref="modalDetallePagoMercadoPago" titulo="Editar CEDIS" tamano="modal-md" no-cerrar no-cancelar no-aceptar>
		<div class="row">
			<div class="col-10">
				<div class="row justify-content-center mx-0">
					<div class="col-6 f-16 fr-light text-general">
						CEDIS
					</div>
					<div class="w-70px" />
				</div>
				<div v-for="(c,key) in cedis" :key="key" class="row justify-content-center my-2">
					<div class="col-6">
						<div class="border br-5 bg-whitesmoke text-general fr-light px-2 f-14 py-1">
							{{ c.nombre }}
						</div>
					</div>
					<div class="col-auto">
						<el-tooltip  effect="light" placement="top">
							<div v-if="c.estado == 0" slot="content">
								<p class="f-12 fr-medium">Activar</p>
								<p class="f-12 fr-light">Se inactivará en la cuenta; {{ c.cuenta_cedis }}</p>
							</div>
							<el-switch v-model="c.estado" :active-value="1" :inactive-value="0" active-color="#28D07B" inactive-color="#DBDBDB" @change="cambioEstado(c)" />
						</el-tooltip>
					</div>
				</div>
			</div>
		</div>
    </modal>
</template>

<script>
import Pagos from "~/services/pasarela-tesoreria/mercado-pago"
export default {
    data(){
        return {
            value:'',
            estado:1,
            cedis:[],
            id_cuenta:null
        }
    },

    methods: {
        toggle(item){
            this.cedis = item
            this.$refs.modalDetallePagoMercadoPago.toggle();
        },
        async cambioEstado(item){
            try {
                const payload = {
                    estado:item.estado
                }
                const {data} = await Pagos.editEstadoCuentaCedis(item.id ,payload)
                this.notificacion('',data.mensaje,'success')
				this.$emit('update')
            } catch (e){
                this.error_catch(e)

            }
        },
    }
}
</script>
